/* eslint-disable max-len */
document.addEventListener( 'DOMContentLoaded', function () {
  let windowWidth = window.innerWidth;

  function checkScroll() {
    if ( window.scrollY > 0 ) {
      document.body.classList.add( 'scrolled' );
    } else {
      document.body.classList.remove( 'scrolled' );
    }
  }

  function setMainMarginTop() {
    const header = document.querySelector( 'header' );
    const main = document.querySelector( '.transparent-header main, .transparent-header .entry-content, .error404 main' );
    if ( header && main && ( windowWidth > 1199 ) ) {
      const headerHeight = header.offsetHeight + 1;
      main.style.marginTop = -headerHeight + 'px';
    } else if ( main ) {
      main.style.marginTop = '0';
    }
  }

  function addMenuHoveredClass() {
    document.querySelector( 'body' ).classList.add( 'menu-hover' );
    document.querySelector( '.max-mega-menu' )?.classList.add( 'mega-menu-hover' );
  }

  function removeMenuHoveredClass() {
    document.querySelector( 'body' ).classList.remove( 'menu-hover' );
    document.querySelector( '.max-mega-menu' )?.classList.remove( 'mega-menu-hover' );
  }

  function appendTopMenuItemsToMain() {
    const sourceItems = document.querySelectorAll( '.e-div--header-top-menu .wp-block-navigation__container > li' );
    const targetContainer = document.querySelector( '.e-div--header-main-menu #mega-menu-primary_menu' );
    if ( targetContainer ) {
      sourceItems?.forEach( item => {
        targetContainer.appendChild( item.cloneNode( true ) );
      } );
    }
  }

  function setActiveClass( subMenus, target ) {
    subMenus?.forEach( menu => menu.classList.remove( 'active' ) );
    target.classList.add( 'active' );
  }

  function handleSubMenuMouseEnter( subMenus ) {
    return function() {
      setActiveClass( subMenus, this );
    };
  }

  function handleMenuMouseLeave( subMenus ) {
    return function() {
      setActiveClass( subMenus, subMenus[0] );
    };
  }

  function initializeMenuBehavior() {
    const menus = document.querySelectorAll( '.dpn-tpe-2' );

    menus?.forEach( menu => {
      const subMenus = menu.querySelectorAll( '.mega-sub-menu-column > .mega-sub-menu > .mega-menu-item' );

      if ( subMenus.length > 0 ) {
        subMenus[0].classList.add( 'active' );
      }

      subMenus?.forEach( subMenu => {
        subMenu.addEventListener( 'mouseenter', handleSubMenuMouseEnter( subMenus ) );
      } );

      menu.addEventListener( 'mouseleave', handleMenuMouseLeave( subMenus ) );
    } );
  }

  function rearrangeLanguageSwitcher () {
    if ( window.innerWidth < 1200 ) {
      const languageSwitcher = document.querySelector( '.wpml-ls' );
      const maxMegaMenu = document.querySelector( 'ul.max-mega-menu' );
  
      if ( languageSwitcher && maxMegaMenu ) {
        const clonedLanguageSwitcher = languageSwitcher.cloneNode( true );
        const newLiForLanguageSwitcher = document.createElement( 'li' );
        newLiForLanguageSwitcher.appendChild( clonedLanguageSwitcher );
        newLiForLanguageSwitcher.classList.add( 'lang-switcher' );
        maxMegaMenu.insertBefore( newLiForLanguageSwitcher, maxMegaMenu.firstChild );
      }
    }
  }

  function handleLanguageCickOnMobile () {
    if ( window.innerWidth < 1200 ) {
      const languageSwitcher = document.querySelector( '.mega-menu-wrap li.lang-switcher li.wpml-ls-current-language' );
      if ( languageSwitcher ) {
        languageSwitcher.addEventListener( 'click', function() {
          this.classList.toggle( 'active' );
        } );
      }
    }
  }

  const menuItems = document.querySelectorAll( '.max-mega-menu > li.mega-menu-item:not(.rst-a-dmo-btn, .menu-search-icon)' );
  menuItems?.forEach( item => {
    item.addEventListener( 'mouseenter', addMenuHoveredClass );
    item.addEventListener( 'mouseleave', removeMenuHoveredClass );
  } );

  checkScroll();
  setMainMarginTop();
  appendTopMenuItemsToMain();
  initializeMenuBehavior();
  rearrangeLanguageSwitcher();
  handleLanguageCickOnMobile();

  window.onscroll = checkScroll;

  window.onresize = function() {
    if ( window.innerWidth != windowWidth ) {
      windowWidth = window.innerWidth;
      setMainMarginTop();
      checkScroll();
    }
  };
} );