const cookieBannerLibraries = ['cookieyes', 'custom'];

// Create an event system
const CookieConcentManager = function () {
  this.events = {};
};

// Add an event listener
CookieConcentManager.prototype.on = function ( event, listener ) {
  if ( typeof this.events[event] !== 'object' ) {
    this.events[event] = [];
  }
  this.events[event].push( listener );
};

// Fire an event
CookieConcentManager.prototype.emit = function ( event, ...args ) {
  if ( typeof this.events[event] === 'object' ) {
    this.events[event]?.forEach( listener => listener.apply( this, args ) );
  }
};

// Create an instance of the event system
let cookieConcentManager = new CookieConcentManager();

const getCookieValue = ( libraryName ) => {
  if ( libraryName == 'cookieyes' ) {
    const cookieName = 'cookieyes-consent';
    // Get all cookies
    let allCookies = document.cookie;
    // Split cookies into an array
    let cookieArray = allCookies.split( ';' );
    // Iterate over each cookie
    for ( let i = 0; i < cookieArray.length; i++ ) {
      let cookie = cookieArray[i].trim();

      // If this cookie string starts with the name we want
      if ( cookie.substring( 0, cookieName.length + 1 ) === cookieName + '=' ) {
        return decodeURIComponent( cookie.substring( cookieName.length + 1 ) );
      }
    }
  }
  // Return null if the cookie wasn't found
  return null;
};

const checkConsent = ( libraryName, cookieValue ) => {
  if ( libraryName == 'cookieyes' ) {
    // Check if cookieValue is null or undefined
    if ( !cookieValue ) {
      return false;
    }

    // Split the cookie value by comma
    let cookieParts = cookieValue.split( ',' );
    // Iterate over each part
    for ( let i = 0; i < cookieParts.length; i++ ) {
      // Split each part by colon to get key-value pairs
      let keyValue = cookieParts[i].split( ':' );
      // Check if the key is 'consent' and if the value is 'yes'
      if ( keyValue[0] === 'consent' && keyValue[1] === 'yes' ) {
        return true;
      }
    }
  }
  // If 'consent:yes' is not found, return false
  return false;
};

const renderIframe = () => {
  const iframes = document.querySelectorAll( 'iframe[data-src]' );
  const consentPlaceholders = document.querySelectorAll( '.b3rg-cookie-consent-placeholder' );
  for ( let iframe of iframes ) {
    iframe.src = iframe.getAttribute( 'data-src' );
  }
  for ( let placeholder of consentPlaceholders ) {
    placeholder.remove();
  }
};

const checkAndRender = ( libraryName ) => {
  const cookieValue = getCookieValue( libraryName );
  const cookieConsentGranted = checkConsent( libraryName, cookieValue );
  if ( cookieConsentGranted ) {
    renderIframe();
  }
};

// Add an event listener
cookieConcentManager.on( 'accept', () => {
  const libraryName = cookieBannerLibraries[0]; // TODO: Library names should be passed dynamically via filters
  checkAndRender( libraryName );
} );

document.addEventListener( 'DOMContentLoaded', () => {
  const libraryName = cookieBannerLibraries[0]; // TODO: Library names should be passed dynamically via filters
  checkAndRender( libraryName );

  const customButtons = document.querySelectorAll(
    '.b3rg-cookie-consent-placeholder__review'
  );
  customButtons?.forEach( ( customButton ) => {
    customButton.addEventListener( 'click', () => {
      const revisitButton = document.querySelector( '.cky-btn-customize' );
      revisitButton.click();
    } );
  } );

  const acceptButtons = document.querySelectorAll(
    '.cky-btn-accept'
  );

  acceptButtons?.forEach( ( acceptButton ) => {
    acceptButton.addEventListener( 'click', () => {
      cookieConcentManager.emit( 'accept' );
    } );
  } );
} );
