function initAnimationForSection( className ) {
  document.addEventListener( 'DOMContentLoaded', function () {
    const sections = document.querySelectorAll( `.${className}` );
    if ( !sections.length ) return;
  
    const observerOptions = { root: null, rootMargin: '0px', threshold: 0.75 };
    const observerCallback = ( entries, observer ) => {
      entries?.forEach( ( entry ) => {
        if ( entry.isIntersecting ) {
          entry.target.querySelectorAll( '.e-svg--background-animation object' )?.forEach( initSvgAnimation );
          observer.unobserve( entry.target );
        }
      } );
    };
  
    const observer = new IntersectionObserver( observerCallback, observerOptions );
    sections?.forEach( ( section ) => observer.observe( section ) );
  } );
  
  function initSvgAnimation( svgObject ) {
    svgObject.style.opacity = 0;
    const svgDoc = svgObject.contentDocument || svgObject.contentWindow.document;
    const player = svgDoc?.querySelector( 'svg' )?.svgatorPlayer || null;
    if ( player ) {
      player.play();
      svgObject.style.transition = 'opacity 1s';
      svgObject.style.opacity = 1;
    }
  }
}
initAnimationForSection( 'e-stn--background-svg-animation' );