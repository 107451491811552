import { slideDown, slideUp, slideToggle, toggleClass } from './common';
const facetFilerAccordion = () => {
  const accWrapper = '.e-div--rgen-facet-filter-acc';
  const activeClass = 'e-div--active';
  const handleElement = 'h6';
  const toggleBody = '.facetwp-facet';
  const accList = Array.from( document.querySelectorAll( accWrapper ) );

  if( accList.length === 0 ) return;
  
  for ( const element of accList ) {
    if( element.classList.contains( activeClass ) ) {
      slideDown( element?.querySelector( toggleBody ) );
    }else {
      slideUp( element?.querySelector( toggleBody ) );
    }
    const handle = element?.querySelector( handleElement );
    handle.addEventListener( 'click', () => {
      slideToggle( handle.nextElementSibling );
      toggleClass( element, activeClass );
    } );
  }
  
};

facetFilerAccordion();