import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );

const stickyContents = gsap.utils.toArray( '.e-stn--on-scroll-animation .e-acc__itm-wrpr' );

function setupScrollAnimation( stickyContents, images, section ) {
  stickyContents?.forEach( ( stickyContent, index ) => {
    const startPosition = window.innerWidth < 991 ? 320 : 400;
    const cardHeight = stickyContent.offsetHeight;

    gsap.to( stickyContent, {
      duration: 1,
      scrollTrigger: {
        trigger: stickyContent,
        start: `top ${startPosition}`,
        end: `+=${cardHeight}`,
        scrub: 0.8,
        invalidateOnResize: true,
        onEnterBack: () => updateImageClass( images, index, section ),
        onEnter: () => updateImageClass( images, index, section ),
      }
    } );
  } );
}

function updateImageClass( images, index, section ) {
  if ( window.innerWidth > 767 ) {
    images?.forEach( image => image.classList.remove( 'active' ) );
    section.querySelectorAll( 'video' )?.forEach( vid => vid.pause() );
    images[index]?.classList.add( 'active' );
    const hasVideo = images[index]?.querySelector( 'video' );
    hasVideo?.play();
  }
}

function updateMediaClassMobile( section ) {
  const accordionHeaders = section.querySelectorAll( '.e-acc__itm-hdr-wrpr' );
  accordionHeaders?.forEach( ( accordionHeader ) => {
    accordionHeader.addEventListener( 'click', function( e ) {
      const parentElement = e.currentTarget.parentElement;
      const video = parentElement?.querySelector( 'video' );
      if ( video ) {
        video.play();
      }
    } );
  } );
}
  
function initSvgAnimation( animationDOM, section ) {
  const svgObject = animationDOM?.querySelector( '.e-svg--trigger-animation object' );
  if ( !svgObject ) return;

  const loadSvgElement = () => {
    const SVGDocument = svgObject.contentDocument || svgObject.contentWindow.document;
    const player = SVGDocument?.querySelector( 'svg' )?.svgatorPlayer || null;
    if ( player ) {
      player.stop();
      const onScroll = () => {
        const rect = section.getBoundingClientRect();
        const scrollPercentage = ( ( section.scrollTop + rect.y - ( window.innerHeight / 2 ) ) / -rect.height ) * 100;
        player.seek( scrollPercentage > 0 ? scrollPercentage + 10 : 0 );
      };
      window.addEventListener( 'scroll', onScroll );
    }
  };
  svgObject.addEventListener( 'load', loadSvgElement );
}

document.addEventListener( 'DOMContentLoaded', () => {
  document.querySelectorAll( '.e-stn--on-scroll-animation' )?.forEach( section => {
    const images = section.querySelectorAll( '.e-acc__flt-itm' );
    const animationDiv = section.querySelector( '.e-div--animation-wrapper' );
    const mediaWrapper = document.createElement( 'div' );
    mediaWrapper.classList.add( 'e-acc__media-wrapper' );
    const accordionTabs = section.querySelectorAll( '.e-acc__itm-wrpr' );

    accordionTabs?.forEach( slide => {
      const mediaElements = slide.querySelectorAll( '.e-acc__flt-itm' );
      mediaElements?.forEach( element => {
        const clonedElement = element.cloneNode( true );
        mediaWrapper.appendChild( clonedElement );
      } );
    } );
    
    if ( animationDiv ){
      mediaWrapper.prepend( animationDiv );
    }

    const container = section.querySelector( '.e-acc' );
    container?.appendChild( mediaWrapper );

    mediaWrapper.querySelectorAll( '.e-acc__flt-itm' )[0]?.classList.add( 'active' );
    setupScrollAnimation( stickyContents, mediaWrapper.querySelectorAll( '.e-acc__flt-itm' ), section );
    initSvgAnimation( animationDiv, section );
    updateImageClass( images, 0, section );
    updateMediaClassMobile( section );
  } );
} );

