document.addEventListener( 'DOMContentLoaded', function () {
  const tabsSection = document.querySelector( '.e-stn--tabs-section, .e-stn--horizontal-tab-with-cta' );

  if ( tabsSection ) {
    const sliderSection = tabsSection.querySelector( '.e-tbs--tabs-section-slider' );
    
    if ( sliderSection && window.innerWidth <= 991 ) {
      // Get the swiper slides from both the nav and content sliders within the container
      const navSlides = sliderSection.querySelectorAll( '.e-tbs__nav .swiper-slide' );
      const contentSlides = sliderSection.querySelectorAll( '.e-tbs__content .swiper-slide' );

      // Iterate through each nav swiper-slide and append the p tag to the corresponding content slide
      navSlides?.forEach( ( navSlide, index ) => {
        const pTag = navSlide.querySelector( 'p' );

        if ( pTag && contentSlides[index] ) {
          const contentWrapper = contentSlides[index].querySelector( '.e-div--content-wrapper' );

          if ( contentWrapper ) {
            const clonedPTag = pTag.cloneNode( true );
            clonedPTag.classList.add( 'cloned-title' );
            contentWrapper.prepend( clonedPTag );
          }
        }
      } );
    }
  }
} );
