import { slideDown, slideUp } from './common';

document.addEventListener( 'DOMContentLoaded', function () {
  const duration = 500;
  const footer = document.querySelector( 'footer' );

  const menuItems = footer.querySelectorAll( 'ul.wp-block-navigation > li.has-child > a' );
  menuItems?.forEach( function ( menuItem ) {
    menuItem.addEventListener( 'click', function ( e ) {
      e.preventDefault();
      const li = e.target.parentElement;
      const isExpanded = li.classList.contains( 'footer-expanded' );

      const expandedItems = footer.querySelectorAll( '.footer-expanded' );
      expandedItems?.forEach( function ( expandedItem ) {
        const subMenu = expandedItem.querySelector( '.wp-block-navigation__submenu-container' );
        if ( subMenu ) {
          slideUp( subMenu, duration );
          expandedItem.classList.remove( 'footer-expanded' );
        }
      } );
      if ( isExpanded ) {
        const subMenu = e.target.parentElement.lastElementChild;
        if ( subMenu ) {
          slideUp( subMenu, duration );
          subMenu.parentElement.classList.remove( 'footer-expanded' );
        }
      } else {
        li.classList.add( 'footer-expanded' );
        const subMenu = e.target.parentElement.lastElementChild;
        if ( subMenu ) {
          slideDown( subMenu, duration );
        }
      }
    } );
  } );

} );