document.addEventListener( 'DOMContentLoaded', function() {
  const scrollButtons = document.querySelectorAll( '.e-btn--scroll-to' );
  if ( !scrollButtons.length ) {
    return;
  }
  const header = document.querySelector( 'header' );
  if ( !header ) {
    return;
  }
  const headerHeight = header.offsetHeight;

  const targetElement = document.querySelector( '.e-stn--current-job-opening' );
  if ( !targetElement ) {
    return;
  }
  scrollButtons?.forEach( function ( scrollButton ) {
    scrollButton.addEventListener( 'click', function( event ) {
      event.preventDefault();
      const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;
      window.scrollTo( {
        top: targetPosition,
        behavior: 'smooth'
      } );
    } );
  } );
} );